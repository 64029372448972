import { MetaSwiper, SafariSwiper } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

const vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  const vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const setSwiperCentering = (swiper, container = document.body) => {
  const { wrapperEl, autoplay, navigation, params, el } = swiper;
  setTimeout(() => {
    if (wrapperEl.scrollWidth <= container.clientWidth) {
      swiper.slideTo(0);
      swiper.allowTouchMove = false;
      autoplay.stop();
      wrapperEl.classList.add("disabled");
      wrapperEl.style.justifyContent = "center";
      wrapperEl.style.left = params.spaceBetween / 2 + "px";

      if (navigation.nextEl) navigation.nextEl.style.visibility = "hidden";
      if (navigation.prevEl) navigation.prevEl.style.visibility = "hidden";
    } else {
      swiper.allowTouchMove = true;
      autoplay.start();
      wrapperEl.classList.remove("disabled");
      wrapperEl.style.justifyContent = null;
      wrapperEl.style.left = null;
      if (navigation.nextEl) navigation.nextEl.style.visibility = "visible";
      if (navigation.prevEl) navigation.prevEl.style.visibility = "visible";
    }
  }, 100);
};

let rellaxFlag = true;
const rellax = new Rellax(".parallax", {
  speed: 3,
  center: true,
});

if (window.innerWidth < 1025) {
  if (rellax.destroy) rellax?.destroy();
}

window.onresize = () => {
  if (rellaxFlag) {
    if (rellax.destroy) rellax.destroy();
    rellaxFlag = false;
  }
};

if (header.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
    }
  });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".mobile-header").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".mobile-navbar"))
    : scrollLock.enablePageScroll();
}
// Close menu
function menuClose() {
  $(".menu-toggle").removeClass("active");
  $(".mobile-header").removeClass("active");
  $(".header-close-wrapper").removeClass("active");

  scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".mobile-header").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Scroll button
const scrollButtons = document.querySelectorAll(".scroll");
if (scrollButtons.length) {
  scrollButtons.forEach((scrollButton) => {
    const sectionScreen =
      scrollButton.closest(".first-section") || scrollButton.closest("section");

    // On scroll
    function scrollProgress() {
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let height = sectionScreen.scrollHeight;
      let scrolled = (winScroll / height) * 100;
      scrollButton.querySelector(".scroll__trigger-fill").style.height =
        scrolled + "%";
    }

    // window.onscroll = function () {
    //   scrollProgress();
    // };
    scrollButton.addEventListener("click", () => {
      const el =
        sectionScreen.nextElementSibling || document.querySelector("footer");

      window.scrollIntoView(el, {
        time: 150,
      });
      // sectionScreen.nextElementSibling.scrollIntoView({
      //   block: "start",
      //   behavior: "smooth",
      // });
    });
  });
}

const caseTitle = document.querySelector(".case-studies__heading");
if (caseTitle && window.innerWidth < 1025)
  caseTitle.classList.remove("parallax");

/* ------- Sliders ------- */

const sectorsSlider = document.querySelector(".sectors-swiper");
if (sectorsSlider) {
  const swiper = new Swiper(sectorsSlider, {
    slidesPerView: "auto",
    spaceBetween: 10,
    speed: 600,
    grabCursor: true,

    // Autoplay
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

    navigation: {
      prevEl: ".swiper-btn-prev.sector-btn",
      nextEl: ".swiper-btn-next.sector-btn",
    },

    breakpoints: {
      551: {
        spaceBetween: 16,
      },
    },
    on: {
      afterInit(swiper) {
        setSwiperCentering(swiper, document.querySelector(".sectors-section"));
        window.addEventListener("orientationchange", () => {
          setSwiperCentering(
            swiper,
            document.querySelector(".sectors-section")
          );
        });
      },
    },
  });

  if (innerWidth > 551) {
    swiper.autoplay.stop();
  }

  SafariSwiper(swiper);
}

const teamSliderDOM = document.querySelector(".team-slider");

if (teamSliderDOM) {
  const teamSlider = new Swiper(teamSliderDOM, {
    slidesPerView: "auto",
    spaceBetween: 16,
    speed: 600,
    grabCursor: true,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    on: {
      afterInit(swiper) {
        setSwiperCentering(swiper, document.querySelector(".results-section"));
        window.addEventListener("orientationchange", () => {
          setSwiperCentering(
            swiper,
            document.querySelector(".results-section")
          );
        });
      },
    },
  });

  SafariSwiper(teamSlider);
}

const caseStudiesItems = document.querySelectorAll(".case-studies-first__item");

if (caseStudiesItems.length) {
  caseStudiesItems.forEach((item) => {
    item.addEventListener("click", () => {
      const { id } = item.dataset;

      const targetSection = document.getElementById(id);
      window.scrollIntoView(targetSection, {
        time: 150,
        ease: function (value) {
          return 1 - Math.cos((value * Math.PI) / 2);
        },
      });
    });
  });
}

const whoWeAreMainContent = document.querySelector(".who-we-are-main-content");

if (whoWeAreMainContent) {
  const tables = whoWeAreMainContent.querySelectorAll("table");

  tables.forEach((table) => {
    table.style = "";
  });
}

// const caseCardLogos = document.querySelectorAll(".case-card__logo");

// caseCardLogos.forEach((logo) => {
//   console.log(
//     logo.naturalWidth,
//     logo.naturalHeight,
//     logo.naturalWidth / logo.naturalHeight
//   );
// });

const headerLinks = document.querySelectorAll(".menu-list__link");
const mobileMenuLinks = document.querySelectorAll(".mobile-menu__link");

const handleHashLinkClick = (e) => {
  e.preventDefault();

  const scrollSection = document.querySelector(e.target.hash + "-custom");

  window.scrollIntoView(scrollSection, {
    time: 150,
  });
};

headerLinks.forEach((link) => {
  if (link.pathname === location.pathname) {
    link.addEventListener("click", handleHashLinkClick);
  }
});

mobileMenuLinks.forEach((link) => {
  if (link.pathname === location.pathname) {
    link.addEventListener("click", (e) => {
      menuClose();
      handleHashLinkClick(e);
    });
  }
});

// For anchor link
window.addEventListener("DOMContentLoaded", (e) => {
  if (location.hash) {
    window.scrollTo(0, 0);

    const scrollSection = document.querySelector(location.hash + "-custom");

    if (scrollSection) {
      window.scrollTo(0, 0);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1);

      setTimeout(() => {
        window.scrollIntoView(scrollSection, {
          time: 150,
        });
      }, 500);
    }
  }
});

const approachSection = document.querySelector(".approach-section");

if (approachSection) {
  const approachItems = approachSection.querySelectorAll(
    ".approach-list__item"
  );

  approachItems.forEach((approachItem) => {
    approachItem.addEventListener("mouseover", () => {
      // const descr = approachItem.querySelector(".approach-list__item-descr");
      // setTimeout(() => (descr.style.overflow = "auto"), 250);

      approachItems.forEach((item) => {
        if (approachItem !== item) {
          item.style.opacity = "0.5";
        } else {
          item.style.opacity = "1";
        }
      });
    });

    approachItem.addEventListener("mouseout", () => {
      // const descr = approachItem.querySelector(".approach-list__item-descr");
      // descr.style.overflow = "hidden";

      approachItems.forEach((item) => {
        item.style.opacity = "1";
      });
    });
  });
}

const aboutSectionCont = document.querySelector(".about-section .cont");
if (aboutSectionCont) {
  aboutSectionCont.style.height =
    aboutSectionCont.getBoundingClientRect().height + "px";

  const typed = new Typed("#typed", {
    stringsElement: "#typed-strings",
    showCursor: false,
    startDelay: 0,
    typeSpeed: 10,
    onComplete() {
      typedSecond.start();
    },
  });
  typed.stop();
  const typedSecond = new Typed("#typed-second", {
    stringsElement: "#typed-second-strings",
    showCursor: false,
    typeSpeed: 10,
    startDelay: 0,
  });
  typedSecond.stop();

  const options = {
    rootMargin: "100px",
    threshold: 0.75,
  };
  const callback = function (entries, observer) {
    if (entries[0].isIntersecting) {
      typed.start();
    }
  };
  const observer = new IntersectionObserver(callback, options);

  var target = document.querySelector(".about-section");
  observer.observe(target);
}
